main {
  min-height: 80vh;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h3 {
  padding: 1rem;
}

.rating span {
  margin: 0.1rem;
}

.pagination {
  display: inline-block;
}

.pagination p {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination p.active {
  background-color: #4CAF50;
  color: white;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

.search-box{
  max-width: 300px;
  display: flex;
  align-items: center;
}

.carousel-image{
  max-height: 15rem;
  max-width: 15rem;
  margin: auto
}

.carousel-col{
  max-height: 22rem;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}